@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.selectedRoomDetails {
  width: 45%;
}

.modalTitle {
  color: $primary900;
  text-align: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.baseModal {
  .baseModalContent {
    padding: 24px 32px;
    min-width: 420px;
    max-width: 490px;
    background-color: $paper;
    max-height: 90vh;
    height: fit-content;
  }

  @media (max-width: $breakpointMD) {
    & :global .MuiPaper-root {
      padding: 16px;
    }

    & :global .MuiDialog-container {
      align-items: flex-end;
    }

    .baseModalContent {
      max-width: 100%;
      min-width: unset;
      width: 100%;
    }
  }
}
