@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/mixins.scss";

// .buttonCommonStyles {
//   height: 40px;
//   padding: 14px 10px;
//   font-size: 12px;
//   font-weight: 500p;
//   line-height: 16px;
//   width: 100%;
//   border-radius: 0;
// }

// .confirmModalTitleWrapper {
//   display: flex;
//   justify-content: center;

//   .confirmModalTitle {
//     color: $primary900;
//     text-align: center;
//     font-size: 16px;
//     font-weight: 600;
//     line-height: 24px;
//     margin: 6px 0 16px;

//     @include for-tablet-landscape-up {
//       margin: 0 0 16px;
//       max-width: 285px;
//     }
//   }
// }
// .confirmButtons {
//   display: flex;
//   flex-direction: column;
//   margin-top: 16px;
//   gap: 8px;

//   @include for-tablet-landscape-up {
//     flex-direction: row;
//     margin-top: 24px;
//   }
// }

// .confirmButton {
//   @extend .buttonCommonStyles;
//   background: $secondary500;
//   color: $paper;

//   &:hover {
//     background: $secondary500;
//   }

//   @include for-tablet-landscape-up {
//     width: 50%;
//   }
// }

// .cancelButton {
//   @extend .buttonCommonStyles;
//   background: $grey150;
//   color: $grey800;

//   &:hover {
//     background: $grey150;
//   }

//   @include for-tablet-landscape-up {
//     width: 50%;
//   }
// }

.baseModal {
  .baseModalContent {
    padding: 24px 32px;
    min-width: 420px;
    max-width: 490px;
    background-color: $paper;
    max-height: 90vh;
    overflow: hidden;
    height: fit-content;
  }

  @media (max-width: $breakpointMD) {
    & :global .MuiPaper-root {
      padding: 16px;
    }

    & :global .MuiDialog-container {
      align-items: flex-end;
    }

    .baseModalContent {
      max-width: 100%;
      min-width: unset;
      width: 100%;
    }
  }
}

.dialogWithExitBtnContainer {
  position: relative;
  z-index: 1;
}

.dialogWithExitExitBtnInner {
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--common-black);
  z-index: 999;
}

.dragBarContainer {
  margin-bottom: 10px;
}

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500p;
  line-height: 16px;
  width: 100%;
  border-radius: 0;
}

.confirmModalTitleWrapper {
  display: flex;
  justify-content: center;

  .confirmModalTitle {
    color: $primary900;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 6px 0 16px;

    @include for-tablet-landscape-up {
      margin: 0 0 16px;
      max-width: 285px;
    }
  }
}
.confirmButtons {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;

  @include for-tablet-landscape-up {
    flex-direction: row;
    margin-top: 24px;
  }
}

.confirmButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;

  &:hover {
    background: $secondary500;
  }

  @include for-tablet-landscape-up {
    width: 50%;
  }
}

.cancelButton {
  @extend .buttonCommonStyles;
  background: $grey150;
  color: $grey800;

  &:hover {
    background: $grey150;
  }

  @include for-tablet-landscape-up {
    width: 50%;
  }
}
