@import "@/src/styles/scss/theme.module.scss";
@import "@/src/styles/variables.scss";

.buttonCommonStyles {
  height: 40px;
  padding: 14px 10px;
  font-size: 12px;
  font-weight: 500p;
  line-height: 16px;
  border-radius: 0;
}

.infoModalTitleWrapper {
  display: flex;
  justify-content: center;

  .infoModalTitle {
    color: $primary900;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 6px 0 16px;
  }
}

.infoButton {
  @extend .buttonCommonStyles;
  background: $secondary500;
  color: $paper;
  width: 100%;

  &:hover {
    background: $secondary500;
  }
}

@media (min-width: $breakpointMD) {
  .infoModalTitleWrapper {
    .infoModalTitle {
      margin: 0 0 16px;
      max-width: 285px;
    }
  }
}


.baseModal {
  .baseModalContent {
    padding: 24px 32px;
    min-width: 420px;
    max-width: 490px;
    background-color: $paper;
    max-height: 90vh;
    height: fit-content;
  }

  @media (max-width: $breakpointMD) {
    & :global .MuiPaper-root {
      padding: 16px;
    }

    & :global .MuiDialog-container {
      align-items: flex-end;
    }

    .baseModalContent {
      max-width: 100%;
      min-width: unset;
      width: 100%;
    }
  }
}

.dialogWithExitBtnContainer {
  position: relative;
  z-index: 1;
}

.dialogWithExitExitBtnInner {
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--common-black);
  z-index: 999;
}

.dragBarContainer {
  margin-bottom: 10px;
}

